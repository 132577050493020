.c-footer {
  width: 100%;
  height: 100%;
  background-color: $dark-gray;

  .top-section {
    margin: 0 auto;
    max-width: 1440px;
    padding: 40px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 64px;
    flex-direction: column;

    @media (min-width: 1024px) {
      padding: 56px;
      flex-direction: row;
      gap: 108px;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      max-width: 337px;
      width: 100%;

      @media (min-width: 768px) {
        max-width: 100%;
      }

      @media (min-width: 1024px) {
        gap: 48px;
        max-width: 337px;
      }

      .logo {
        width: 193px;
        height: 56px;

        @media (min-width: 1024px) {
          width: 242px;
          height: 70px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .list-socials {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .list {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin: 0;
          padding: 0;

          .list-item {
            display: flex;
            gap: 16px;

            .details {
              align-self: center;
              color: #fff;
              font-family: $source-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 130%;
              opacity: 0.8;
              text-decoration: none;

              a {
                align-self: center;
                color: #fff;
                font-family: $source-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 130%;
                opacity: 0.8;
                text-decoration: none;
              }
            }
          }
        }

        .socials {
          list-style-type: none;
          padding: 0;
          display: flex;
          gap: 24px;
          margin: 0;
        }
      }
    }

    .right-content {
      display: flex;
      flex-direction: column;
      gap: 48px;
      width: 100%;
    }
  }

  .bottom-section {
    margin: 0 auto;
    max-width: 1440px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 1024px) {
      padding: 24px 56px;
      border-top: 1px solid rgba(238, 238, 238, 0.5);
      flex-direction: row;
      align-items: center;
      gap: unset;
    }

    span {
      color: #fff;
      font-family: $source-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      opacity: 0.8;
    }

    ul {
      text-decoration: none;
      padding: 0;
      display: flex;
      gap: 24px;
      align-items: center;
      margin: 0;
      list-style-type: none;

      li {
        width: 50%;

        @media (min-width: 1024px) {
          width: unset;
        }

        a {
          text-decoration: none;
          color: #fff;
          font-family: $source-font;
          font-size: 16px;
          font-weight: 600;
          line-height: 100%; /* 16px */

          &:hover {
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.c-footer-talk-to-us {
  border-radius: 16px;
  background: #fff;
  gap: 30px;
  padding: 24px;
  max-width: 610px;
  align-self: end;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5 {
      color: $dark-gray;
      font-family: $nunito-font;
      font-size: 31px;
      font-weight: 800;
      line-height: 100%; /* 31px */
      letter-spacing: -0.62px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    p {
      color: rgba(27, 27, 27, 0.8);
      font-family: $source-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;

      @media (min-width: 1024px) {
        -webkit-line-clamp: 2;
      }
    }
  }
}

.c-footer-menus {
  display: flex;
  gap: 40px;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 24px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media (min-width: 1024px) {
      max-width: 206px;
    }

    .menu-title {
      border: 0;
      text-align: unset;
      background-color: transparent;
      text-decoration: none;
      color: #fff;
      font-family: $source-font;
      padding: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
    }

    ul {
      margin: 0;
      padding: 0;
      gap: 12px;
      list-style-type: none;
      width: 100%;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      display: grid;

      @media (min-width: 1024px) {
        display: flex;
        gap: 16px;
        flex-direction: column;
        grid-template-columns: unset;
      }

      li {
        a,
        button {
          border: 0;
          text-align: unset;
          background-color: transparent;
          color: #fff;
          font-family: $source-font;
          font-size: 13px;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
          text-decoration: none;

          @media (min-width: 1024px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .highlight-links {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    list-style-type: none;
    width: 100%;

    @media (min-width: 1024px) {
      max-width: 206px;
      gap: 24px;
    }

    li {
      a,
      button {
        border: 0;
        text-align: unset;
        background-color: transparent;
        text-decoration: none;
        color: #fff;
        font-family: $source-font;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }

      .mobile-separator {
        margin-top: 16px;
        display: block;

        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
  }
}

.mobile-separator {
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid $mid-grey;

  @media (min-width: 1024px) {
    display: none;
  }
}

.c-footer-talk-to-us-mobile {
  border-radius: 8px;
  background: #fff;
  gap: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: none;
    flex-direction: row;
    gap: 30px;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5 {
      color: $dark-gray;
      font-family: $nunito-font;
      font-weight: 800;
      line-height: 100%; /* 31px */
      font-size: 23px;
      letter-spacing: -0.46px;

      @media (min-width: 1024px) {
        font-size: 31px;
        letter-spacing: -0.62px;
      }
    }

    p {
      color: rgba(27, 27, 27, 0.8);
      font-family: $source-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }
  }
}
