.c-primary-btn {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 80px;
  background: $c-red;
  font-family: $source-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%; /* 16px */
  color: #fff;
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
  transition: all 0.3s ease;

  &:hover {
    color: #fff;
    cursor: pointer;
    background-color: $red-r400;
    text-decoration: none;
  }

  &.invi {
    color: $c-red;
    background-color: transparent;
    padding: 4px 0px;
  }
}

.c-primary-outline-btn {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 80px;
  border: 1px solid $c-red;
  font-family: $source-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%; /* 16px */
  color: $c-red;
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: $red-r400;
    color: #fff;
    cursor: pointer;

    svg {
      path {
        fill: #fff;
        transition: fill 0.3s; /* Smooth transition for fill color */
      }
    }
  }
}

.custom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 56px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 16px;

  @media (min-width: 1024px) {
    padding: 80px 40px;
  }

  .o-skeleton-container {
    padding: 0;
  }
}

body {
  padding-bottom: 0;
  padding-top: 0;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.hidden {
  display: none !important;
}

.c-capsule-btn {
  opacity: 0.7;
  text-transform: initial;
  background-color: #d32131;
  font-weight: normal;
  font-size: 12px;
  padding: 10px 20px;
  margin-right: 5px;
  cursor: pointer;
  font-family: $nunito-font;
  color: #fff;
  border: none;
  text-decoration: none;
  border-radius: 20px;
}

/* Container for the skeleton loading effect */
.o-skeleton-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 16px;
  width: 100%;

  @media (min-width: 1024px) {
    padding: 40px;
  }

  &.no-padding {
    padding: 0; // override to remove padding when noPadding is true

    .skeleton-content {
      border-radius: 0;
    }
  }

  .skeleton-content {
    position: relative;
    background-color: #e0e0e0;
    overflow: hidden;

    // Use desktop props for larger screens
    width: var(--desktop-width);
    height: var(--desktop-height);
    border-radius: 32px;

    // Mobile styles using media query
    @media (max-width: 1023px) {
      width: var(--mobile-width);
      height: var(--mobile-height);
      border-radius: 16px;
    }

    .skeleton {
      width: 100%;
      height: 100%;
      background-color: #e0e0e0;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -150px;
        height: 100%;
        width: 150px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0)
        );
        animation: shimmer 1.5s infinite;
      }

      @keyframes shimmer {
        0% {
          left: -150px;
        }
        100% {
          left: 100%;
        }
      }
    }
  }
}

.c-homepage {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
}
