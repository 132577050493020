.c-policies-processes-practices {
  max-width: 1440px;
  display: flex;
  gap: 36px;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 668px;

    @media (min-width: 768px) {
      max-width: 100%;
    }

    @media (min-width: 1024px) {
      flex-shrink: 0;
      gap: 56px;
      max-width: 668px;
    }

    .title-cta {
      display: flex;
      gap: 24px;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
      }

      h4 {
        color: #000;
        font-family: $nunito-font;
        font-weight: 800;
        line-height: 100%; /* 39px */
        font-size: 26px;
        letter-spacing: -0.52px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        @media (min-width: 1024px) {
          font-size: 39px;
          letter-spacing: -0.78px;
        }
      }
    }

    .downloadable-cards {
      display: flex;
      gap: 24px;
      width: 100%;

      .swiper-container {
        width: 100%;
      }
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    gap: 56px;
    width: 100%;

    .image {
      max-width: 343px;
      max-height: 343px;

      @media (min-width: 768px) {
        max-width: 100%;
        max-height: 100%;
      }

      @media (min-width: 1024px) {
        max-width: 619px;
        max-height: 354px;
      }

      img {
        border-radius: 16px;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (min-width: 1024px) {
          border-radius: 32px;
        }
      }
    }
  }
}
