.c-careers {
  width: 100%;
  height: 405px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.30) 0%,
      rgba(0, 0, 0, 0.30) 100%
    ),
    var(--mobile-background-url) lightgray 50% / cover no-repeat;

  @media (min-width: 1024px) {
    height: 397px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.30) 0%,
      rgba(0, 0, 0, 0.30) 100%
    ),
    var(--background-url) lightgray 50% / cover no-repeat;
  }

  .centered-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    max-width: 343px;

    @media (min-width: 1024px) {
      max-width: 672px;
    }

    h2 {
      color: #fff;
      text-align: center;
      font-family: $nunito-font;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      letter-spacing: -0.64px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;

      @media (min-width: 1024px) {
        font-size: 61px;
        letter-spacing: -1.22px;
        -webkit-line-clamp: 2;
      }
    }

    .desc-cta {
      max-width: 417px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      p {
        color: #fff;
        text-align: center;
        font-family: $source-font;
        font-size: 18px;
        font-weight: 400;
        line-height: 130%; /* 23.4px */
        opacity: 0.8;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;

        @media (min-width: 1024px) {
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}
