.c-downloadable-card {
  padding: 16px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  width: 100%;
  height: 260px;
  transition: background-color 0.3s, border-color 0.3s;
  flex-direction: column;

  @media (min-width: 768px) {
    height: 220px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 24px;
    border-radius: 16px;
  }

  &:hover {
    text-decoration: none;
    background-color: $red-r200;
    cursor: pointer;

    .icon {
      background-color: $c-red;
      border-color: $c-red; /* Add transition to border color */

      path {
        fill: #ffffff;
        transition: fill 0.3s; /* Smooth transition for fill color */
      }
    }

    .title-date {
      p {
        color: $c-red;
        transition: color 0.3s; /* Smooth transition for text color */
      }
      .title-desc {
        .title {
          color: $c-red;
          transition: color 0.3s; /* Smooth transition for text color */
        }
        .description {
          display: -webkit-box;
        }
      }
    }
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 1000px;
    border: 1px solid $c-red;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s; /* Smooth transition for border color */

    svg {
      display: flex;
      flex-shrink: 0;
    }
  }

  .title-date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title-desc {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        color: $dark-gray;
        font-family: $source-font;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        transition: color 0.3s; /* Smooth transition for text color */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .description {
        color: $dark-gray;
        font-family: $source-font;
        font-size: 13px;
        font-weight: 400;
        line-height: 130%; /* 16.9px */
        opacity: 0.8;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;

        @media (min-width: 1024px) {
          display: none;
        }
      }
    }

    span {
      color: $mid-grey;
      font-family: $source-font;
      font-size: 13px;
      font-weight: 400;
      line-height: 130%;
      opacity: 0.8;
    }
  }
}
