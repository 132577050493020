.c-header {
  display: flex;
  flex-direction: column;
  width: 100%;

  .top-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    background-color: $red-r400;
    z-index: 3;

    .items {
      width: 100%;
      margin: 0 auto;
      max-width: 1440px;
      height: 10px;
      padding: 0px 40px;
      display: flex;

      @media (min-width: 1024px) {
        height: 48px;
      }

      &::before {
        content: "";
        display: flex;
        width: 100%;
      }

      .ctas {
        gap: 24px;
        align-items: center;
        display: none;

        @media (min-width: 1024px) {
          display: flex;
          flex-shrink: 0;
        }

        a {
          color: #fff;
          font-family: $source-font;
          font-size: 16px;
          font-weight: 600;
          line-height: 100%; /* 16px */
          text-decoration: none;
        }

        button {
          background-color: transparent;
          border: none;
        }
      }
    }
  }

  .bottom-nav {
    border-bottom: 1px solid #eee;
    background: #fff;
    height: 86px;
    align-items: center;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      z-index: 3;
    }

    .items {
      margin: 0 auto;
      display: flex;
      max-width: 1440px;
      padding: 0px 40px;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .logo {
        width: 194.874px;
        height: 54px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .menus {
        display: flex;
        gap: 16px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        align-items: center;

        .menu-item {
          display: flex;

          .link-item {
            color: $dark-gray;
            font-family: $source-font;
            font-size: 16px;
            font-weight: 600;
            line-height: 100%; /* 16px */
            display: flex;
            gap: 4px;
            align-items: center;
            padding: 10px 16px;

            &:hover {
              border-radius: 200px;
              background: #eee;
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.c-header-mobile-nav {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  display: flex;

  @media (min-width: 1024px) {
    display: none;
  }

  nav {
    padding: 19px 16px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    height: 70px;
    z-index: 3;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    @media (min-width: 1024px) {
      display: none;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }

  &__logo {
    width: 108px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__menus {
    position: absolute;
    transform: translateY(-100%);
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 2;
    transition: transform 0.5s ease-in-out;
    padding: 0px 16px;
    overflow: auto;

    &.active {
      transform: translateY(0);
    }

    &::before {
      display: flex;
      content: "";
      width: 100%;
      height: calc(70px + 10px + 24px);
    }

    .mobile-menu-list {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;

      li {
        a {
          padding: 18px 0px;
          display: flex;
          color: $dark-gray;
          font-family: $source-font;
          font-size: 16px;
          font-weight: 600;
          line-height: 100%; /* 16px */
        }
      }
    }
  }
}

.c-mobile-search-bar {
  display: flex;
  width: 100%;

  form {
    display: flex;
    gap: 16px;
    width: 100%;
    height: 59px;
    border-bottom: 1px solid #5d5d5d;
    align-items: center;

    input {
      width: 100%;
      border: 0;
      font-family: $source-font;
      font-size: 18px;
      font-weight: 400;
      line-height: 130%; /* 23.4px */
      color: #5d5d5d;
    }

    svg {
      display: flex;
      flex-shrink: 0;
    }
  }
}
