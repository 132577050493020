.c-search-bar {
  background-color: #f5f5f5;
  width: 100%;
  padding: 48px 40px;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }

  &__input-search {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 40px;

    .input {
      display: flex;
      width: 100%;
      position: relative;

      input {
        border: 0;
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-radius: 0;
        border-bottom: 2px solid #a9a8a8;
        font-size: 1.3rem;
        color: #111111;
        opacity: 0.7;
        background-color: transparent;
      }

      svg {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: 0;
        width: 25px;
        height: 25px;

        path {
          fill: $c-red;
        }
      }
    }

    .suggestions {
      display: flex;
      gap: 4px;
    }
  }
}
