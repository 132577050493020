.c-banner {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding: 16px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 31px 40px 29px 40px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        width: 100%;
        height: 100%;
        position: relative;

        .desktop {
          display: none;

          @media (min-width: 1024px) {
            display: block;
          }
        }

        .mobile {
          display: block;

          @media (min-width: 1024px) {
            display: none;
          }
        }

        img {
          width: 100%;
          height: 698px;
          object-fit: cover;
          border-radius: 16px;

          @media (min-width: 1024px) {
            border-radius: 32px;
            height: 677px;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.3) 0%,
              rgba(0, 0, 0, 0.3) 100%
            );
            z-index: 1;
          }
        }

        .content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          gap: 24px;
          width: 100%;
          align-items: center;
          max-width: 295px;

          @media (min-width: 1024px) {
            max-width: 897px;
          }

          h1 {
            color: #fff;
            text-align: center;
            font-family: $nunito-font;
            font-weight: 800;
            line-height: 100%; /* 76px */
            font-size: 36px;
            letter-spacing: -0.72px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;

            @media (min-width: 1024px) {
              font-size: 76px;
              letter-spacing: -1.52px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: transparent;
        opacity: 1;
        border: 1px white solid;

        &-active {
          width: 10px;
          height: 10px;
          border: none;
          background-color: white;
        }
      }
    }

    .swiper-button-prev {
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 1000px;
      border: 1px solid #fff;
      margin-left: 40px;
      display: none;

      @media (min-width: 1024px) {
        display: flex;
      }

      &::after {
        display: none;
      }
    }

    .swiper-button-next {
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 1000px;
      border: 1px solid #fff;
      margin-right: 40px;
      display: none;

      @media (min-width: 1024px) {
        display: flex;
      }

      &::after {
        display: none;
      }
    }

    .swiper-button-disabled {
      opacity: 1;
    }
  }
}
