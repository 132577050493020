// Fonts
$nunito-font: "Nunito Sans", sans-serif;
$source-font: "Source Sans 3", sans-serif;
// Colors
$c-red: #951b28;
$red-r400: #d32130;
$red-r200: #fcf1f1;
$dark-gray: #1b1b1b;
$grey: #eeeeee;
$grey-r400: #272d33;
$mid-grey: #5d5d5d;
$stat-green: #04c900;
$body-2: #767676;
