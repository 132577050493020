.c-corporate-governance {
  max-width: 1440px;
  display: flex;
  gap: 24px;
  flex-direction: column-reverse;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 73px;
  }

  .left-content {
    width: 100%;
    height: 343px;
    display: flex;

    @media (min-width: 768px) {
      height: 100%;
    }

    @media (min-width: 1024px) {
      width: 619px;
      height: 674px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;

      @media (min-width: 1024px) {
        border-radius: 32px;
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 16px;

      @media (min-width: 1024px) {
        border-radius: 32px;
      }
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 668px;

    @media (min-width: 1024px) {
      gap: 36px;
    }

    .title-cta {
      display: flex;
      gap: 16px;
      align-items: start;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
      }

      h2 {
        margin: 0;
        color: $dark-gray;
        font-family: $nunito-font;
        font-weight: 800;
        line-height: 100%; /* 61px */
        font-size: 32px;
        letter-spacing: -0.64px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        @media (min-width: 1024px) {
          letter-spacing: -1.22px;
          font-size: 61px;
        }
      }
    }

    .list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        a {
          display: flex;
          align-items: center;
          gap: 16px;
          transition: all 0.3s ease;

          @media (min-width: 1024px) {
            padding: 30px 20px;
            gap: 56px;
          }

          .texts {
            display: flex;
            flex-direction: column;
            width: 100%;

            h3 {
              color: $c-red;
              font-family: $source-font;
              font-size: 20px;
              font-weight: 600;
              line-height: 120%; /* 28.8px */
              letter-spacing: -0.24px;
              margin-bottom: 8px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;

              @media (min-width: 1024px) {
                font-size: 24px;
                -webkit-line-clamp: 1;
              }
            }

            p {
              margin: 0;
              color: #000;
              font-family: $source-font;
              font-size: 18px;
              font-weight: 400;
              line-height: 130%; /* 23.4px */
              opacity: 0.8;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 8;
              overflow: hidden;

              @media (min-width: 1024px) {
                -webkit-line-clamp: 5;
              }
            }
          }

          svg {
            display: flex;
            flex-shrink: 0;
          }

          &:hover {
            text-decoration: none;
            border-radius: 16px;
            background-color: $grey;
            cursor: pointer;

            svg {
              path {
                fill: $c-red;
                transition: fill 0.3s; /* Smooth transition for fill color */
              }
            }
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background-color: #00000033;
        margin: 24px 0px;

        @media (min-width: 1024px) {
          margin: 16px 0px;
        }
      }
    }
  }
}
