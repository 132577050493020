.c-menu-dropdown {
  position: relative;
  display: flex;
  gap: 4px;
  cursor: pointer;
  padding: 10px 16px;

  &.is-open {
    border-radius: 200px;
    background: #eee;
    cursor: pointer;
  }

  &:hover {
    border-radius: 200px;
    background: #eee;
    cursor: pointer;
  }

  span {
    color: $dark-gray;
    font-family: $source-font;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%; /* 16px */
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .submenu {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 53px;
    left: 0;
    border-radius: 0px 0px 16px 16px;
    background: #fff;
    z-index: 2;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 260px;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease;
    padding: 24px 24px 32px 24px; /* Moved padding here */

    &.active {
      overflow: visible;
      transform: scaleY(1);
    }

    li {
      a,
      button {
        border: 0;
        background-color: transparent;
        color: $dark-gray;
        font-family: $source-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        padding: 0;
        padding: 8px;
        width: 100%;
        display: inline-flex;
        text-align: left;

        &:hover {
          text-decoration: none;
          border-radius: 24px;
          background: #eee;
        }
      }
    }
  }
}

.c-menu-subnav {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 12px;

  .main-nav {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 100%; /* 16px */
    font-family: $source-font;
    padding: 8px;
    gap: unset;
    justify-content: space-between;

    svg {
      display: flex;
      flex-shrink: 0;
    }

    &.is-open {
      border-radius: 24px;
      background: #eee;
      font-weight: 600;
    }

    &:hover {
      border-radius: 24px;
      background: #eee;
    }
  }

  .subnavs {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    padding-left: 24px;
    display: flex;
    gap: 8px;
    list-style-type: none;
    max-height: 0px; /* Initially collapsed */
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    opacity: 0;

    &.active {
      opacity: 1;
      max-height: 1000px; /* Adjust based on content size */
      overflow: visible;
    }

    &-item {
      .link {
        display: flex;
        width: 100%;
        color: $mid-grey;
        font-family: $source-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%; /* 20.8px */

        &:hover {
          background-color: #fff;
          color: $dark-gray;
          font-size: 16px;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
        }
      }
    }
  }
}
