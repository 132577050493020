.c-latest-news {
  max-width: 1440px;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 40px;

  @media (min-width: 1024px) {
    padding: 80px 40px;
    gap: 56px;
  }

  .title-cta {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    h3 {
      color: $dark-gray;
      text-align: center;
      font-family: $nunito-font;
      font-size: 29px;
      font-weight: 800;
      line-height: 100%; /* 29px */
      letter-spacing: -0.58px;
      margin-bottom: 58px;
      margin: 0;

      @media (min-width: 1024px) {
        font-size: 49px;
        letter-spacing: -0.98px;
      }
    }
  }

  .featured-news {
    display: flex;
    gap: 24px;
    flex-direction: column;
    width: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .left-content {
      width: 100%;
      height: 192.938px;
      max-width: 668px;
      display: flex;
      flex-shrink: 0;

      @media (min-width: 768px) {
        max-width: 100%;
        height: 100%;
      }

      @media (min-width: 1024px) {
        height: 375.75px;
        max-width: 668px;
      }

      img {
        border-radius: 24px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: 1024px) {
        padding: 32px;
        gap: 24px;
      }

      .head-title {
        display: flex;
        flex-direction: column;
        gap: 8px;

        span {
          color: $c-red;
          font-family: $source-font;
          font-size: 18px;
          font-weight: 600;
          line-height: 100%; /* 18px */
          letter-spacing: 4.5px;
          text-transform: uppercase;
        }

        h6 {
          color: $dark-gray;
          font-family: $source-font;
          font-size: 20px;
          font-weight: 600;
          line-height: 120%; /* 24px */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3; /* Number of lines to show */

          @media (min-width: 1024px) {
            font-size: 40px;
          }
        }
      }

      p {
        color: rgba(27, 27, 27, 0.8);
        font-family: $source-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3; /* Number of lines to show */
        opacity: 0.8;
      }

      a {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #000;
        font-family: $source-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%; /* 25.6px */

        svg {
          display: flex;
          flex-shrink: 0;
        }
      }
    }
  }

  .cards {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 25px;
    }
  }
}

.c-latest-news-card {
  display: flex;
  gap: 24px;
  max-width: 437px;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    width: calc(100% / 3);
  }

  img {
    object-fit: cover;
    border-radius: 16px;
    width: 104px;
    height: 104px;

    @media (min-width: 1024px) {
      width: 132px;
      height: 132px;
    }
  }

  .texts-cta {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .texts {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        color: $red-r400;
        font-family: $source-font;
        font-size: 18px;
        font-weight: 600;
        line-height: 100%; /* 18px */
        letter-spacing: 4.5px;
        text-transform: uppercase;
      }

      p {
        color: $grey-r400;
        font-family: $source-font;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%; /* 24px */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3; /* Number of lines to show */

        @media (min-width: 1024px) {
          -webkit-line-clamp: 2; /* Number of lines to show */
          font-size: 20px;
        }
      }
    }

    .cta {
      display: flex;
      gap: 8px;
      color: $dark-gray;
      font-family: $source-font;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%; /* 16px */
      align-items: center;

      svg {
        display: flex;
        flex-shrink: 0;
      }
    }
  }
}
