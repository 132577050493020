.c-our-company {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding: 16px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 40px;
  }

  &__bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 24px;
    justify-content: space-between;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    gap: 40px;

    @media (min-width: 1024px) {
      padding: 48px;
      height: 552px;
      gap: 56px;
      border-radius: 32px;
    }

    .bg-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 417px;
      color: #ffffff;

      .texts {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h2 {
          font-family: $nunito-font;
          font-weight: 800;
          line-height: 100%; /* 61px */
          font-size: 32px;
          letter-spacing: -0.64px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;

          @media (min-width: 1024px) {
            font-size: 61px;
            letter-spacing: -1.22px;
            -webkit-line-clamp: 1;
          }
        }

        p {
          font-family: $source-font;
          font-size: 18px;
          font-weight: 400;
          line-height: 130%; /* 23.4px */
          opacity: 0.8;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 6;

          @media (min-width: 1024px) {
            -webkit-line-clamp: 4;
          }
        }
      }
    }

    .bg-cards {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
        gap: 24px;
      }

      .card {
        padding: 16px;
        display: flex;
        gap: 16px;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
          0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        max-width: 100%;

        @media (min-width: 1024px) {
          max-width: calc(100% / 3);
          height: 174px;
          padding: 24px;
        }

        span {
          color: $c-red;
          font-family: $source-font;
          font-size: 20px;
          font-weight: 600;
          line-height: 120%; /* 28.8px */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 1;

          @media (min-width: 1024px) {
            font-size: 24px;
          }
        }

        p {
          color: $dark-gray;
          opacity: 0.8;
          text-align: center;
          font-family: $source-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 6;

          @media (min-width: 1024px) {
            -webkit-line-clamp: 4;
          }
        }
      }
    }
  }
}
