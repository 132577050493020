.c-latest-disclosures {
  max-width: 1440px;
  // margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1024px) {
    gap: 56px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: unset;
    }

    h4 {
      color: #000;
      font-family: $nunito-font;
      font-weight: 800;
      line-height: 100%; /* 39px */
      font-size: 26px;
      letter-spacing: -0.52px;

      @media (min-width: 1024px) {
        font-size: 39px;
        letter-spacing: -0.78px;
      }
    }

    .ctas {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
        gap: 24px;
      }

      .c-primary-btn {
        width: 100%;
        justify-content: center;

        @media (min-width: 1024px) {
          width: auto;
        }
      }

      .c-primary-outline-btn {
        width: 100%;
        justify-content: center;

        @media (min-width: 1024px) {
          width: auto;
        }
      }
    }
  }

  .cards {
    width: 100%;
    display: flex;

    .swiper-container {
      width: 100%;
    }
  }
}
