.c-investor-relations {
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 1024px) {
    padding: 80px 40px;
    gap: 56px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 1024px) {
      justify-content: space-between;
      flex-direction: row;
      gap: unset;
    }

    h2 {
      color: $dark-gray;
      font-family: $nunito-font;
      font-weight: 800;
      line-height: 100%; /* 61px */
      font-size: 32px;
      letter-spacing: -0.64px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;

      @media (min-width: 1024px) {
        font-size: 61px;
        letter-spacing: -1.22px;
      }
    }
  }

  .highlights {
    display: flex;
    gap: 40px;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 73px;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      max-width: 618px;

      @media (min-width: 768px) {
        max-width: 100%;
      }

      @media (min-width: 1024px) {
        max-width: 618px;
      }

      h6 {
        color: $dark-gray;
        font-family: $nunito-font;
        font-weight: 800;
        line-height: 100%; /* 25px */
        font-size: 20px;
        letter-spacing: -0.4px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;

        @media (min-width: 1024px) {
          font-size: 25px;
          letter-spacing: -0.5px;
        }
      }

      .boxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        @media (min-width: 1024px) {
          gap: 24px;
        }

        .box-card {
          width: auto;
          height: 180px;
          border-radius: 8px;
          background: $red-r200;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 16px;

          @media (min-width: 1024px) {
            height: 256px;
            padding: 24px;
            border-radius: 16px;
          }

          .percentage-number {
            display: flex;
            gap: 8px;
            flex-direction: column;

            @media (min-width: 1024px) {
              flex-direction: row;
              align-items: center;
            }

            .percentage {
              display: flex;
              gap: 4px;
              align-items: center;
              color: $stat-green;
              font-family: $source-font;
              font-size: 18px;
              font-weight: 400;
              line-height: 100%; /* 18px */
              letter-spacing: -0.54px;

              svg {
                display: flex;
                flex-shrink: 0;
              }
            }

            .number {
              color: $dark-gray;
              font-family: $source-font;
              font-weight: 400;
              line-height: 100%; /* 72px */
              font-size: 32px;
              letter-spacing: -0.96px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;

              @media (min-width: 1024px) {
                font-size: 72px;
                letter-spacing: -2.16px;
              }
            }
          }

          p {
            color: rgba(27, 27, 27, 0.8);
            font-family: $source-font;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%; /* 24px */
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;

            @media (min-width: 1024px) {
              font-size: 20px;
            }
          }
        }
      }
    }

    .right-content {
      display: flex;
      width: 100%;

      .list {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        width: 100%;
        padding: 0;

        li {
          a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            @media (min-width: 1024px) {
              gap: 56px;
              padding: 30px 20px;
            }

            svg {
              display: flex;
              flex-shrink: 0;
            }

            .title-desc {
              max-width: 540px;
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 8px;

              .title {
                color: $c-red;
                font-family: $source-font;
                font-size: 24px;
                font-weight: 600;
                line-height: 120%; /* 28.8px */
                letter-spacing: -0.24px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }

              p {
                color: #000;
                font-family: $source-font;
                font-size: 18px;
                font-weight: 400;
                line-height: 130%; /* 23.4px */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                overflow: hidden;
                opacity: 0.8;

                @media (min-width: 1024px) {
                  -webkit-line-clamp: 3;
                }
              }
            }

            &:hover {
              text-decoration: none;
              border-radius: 16px;
              background-color: $grey;
              cursor: pointer;

              svg {
                path {
                  fill: $c-red;
                  transition: fill 0.3s; /* Smooth transition for fill color */
                }
              }
            }
          }
        }

        .separator {
          width: 100%;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.2);
          margin: 24px 0px;

          @media (min-width: 1024px) {
            margin: 16px 0px;
          }
        }
      }
    }
  }

  .downloads {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @media (min-width: 1024px) {
      gap: 56px;
    }

    h4 {
      color: $dark-gray;
      text-align: center;
      font-family: $nunito-font;
      font-weight: 800;
      line-height: 100%; /* 39px */
      font-size: 26px;
      letter-spacing: -0.52px;

      @media (min-width: 1024px) {
        font-size: 39px;
        letter-spacing: -0.78px;
      }
    }

    .cards {
      display: flex;
      gap: 40px;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
        gap: 24px;
        justify-content: center;
      }

      .downloadable-card {
        display: flex;
        gap: 24px;
        align-items: center;
        width: 100%;
        flex-direction: column;

        @media (min-width: 1024px) {
          flex-direction: row;
          width: 50%;
        }

        .thumbnail {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;

          @media (min-width: 1024px) {
            flex-shrink: 0;
            width: 304px;
            height: 309px;
          }

          img {
            object-fit: cover;
            width: 154.671px;
            height: 213px;
            border-radius: 8px;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
              0px 4px 8px 0px rgba(0, 0, 0, 0.06);

            @media (min-width: 1024px) {
              width: 224px;
              height: 309px;
            }
          }
        }

        .texts-cta {
          display: flex;
          flex-direction: column;
          gap: 24px;
          align-items: center;

          @media (min-width: 1024px) {
            align-items: start;
          }

          .texts {
            display: flex;
            flex-direction: column;
            gap: 8px;

            span {
              color: $c-red;
              font-family: $source-font;
              font-size: 18px;
              font-weight: 600;
              line-height: 100%; /* 18px */
              letter-spacing: 4.5px;
              text-transform: uppercase;
              text-align: center;
              opacity: 0.8;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;

              @media (min-width: 1024px) {
                text-align: start;
              }
            }

            p {
              color: $dark-gray;
              font-family: $source-font;
              font-weight: 600;
              line-height: 120%; /* 28.8px */
              text-align: center;
              font-size: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
              overflow: hidden;

              @media (min-width: 1024px) {
                text-align: start;
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}
