.c-our-business {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px 16px;
  gap: 40px;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 40px;
    gap: 24px;
    flex-direction: row;
  }

  &__left-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 436px;
    width: 100%;
    
    @media (min-width: 768px) {
      max-width: 100%;
    }

    @media (min-width: 1024px) {
      max-width: 436px;
    }

    h2 {
      color: #000;
      font-family: $nunito-font;
      font-weight: 800;
      line-height: 100%;
      font-size: 29px;
      letter-spacing: -0.58px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;

      @media (min-width: 1024px) {
        font-size: 61px;
        letter-spacing: -1.22px;
        -webkit-line-clamp: 1;
      }
    }

    p {
      color: $dark-gray;
      font-family: $source-font;
      font-size: 18px;
      font-weight: 400;
      line-height: 130%; /* 23.4px */
      opacity: 0.8;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 6;

      @media (min-width: 1024px) {
        -webkit-line-clamp: 5;
      }
    }
  }

  &__right-content {
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;

    @media (min-width: 1024px) {
      display: flex;
    }
  }

  &__right-content-mobile {
    display: flex;
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      display: none;
    }
  }
}

.c-logo-card {
  flex: 1 1 calc(33.333% - 16px); /* Flex grow, shrink, basis */
  max-width: calc(33.333% - 16px);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 207px;
  border-radius: 16px;
  border: 1px solid #eeeeee;
  flex-direction: column;
  overflow: hidden;

  img {
    display: flex;
    object-fit: cover;
    width: 184px;
    height: 94px;
    flex-shrink: 0;
    transition: all 0.3s ease-in;
    transform: translateY(63%);
  }

  .hovered-content {
    transition: all 0.3s ease-in;
    padding: 0px 24px 24px 24px;
    flex-direction: column;
    gap: 16px;
    transform: translateY(100%);
    display: flex;
    align-items: start;

    p {
      text-align: start;
      color: #1b1b1bcc;
      font-family: $source-font;
      font-size: 13px;
      font-weight: 400;
      line-height: 130%; /* 16.9px */
      margin-bottom: 0;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.8;
    }
  }

  &:hover {
    border: 1px solid $c-red;

    img {
      transform: translateY(0);
      margin-top: 24px;
      margin-bottom: 16px;
      width: 102px;
      height: 52px;
    }

    .hovered-content {
      transform: translateY(0);
    }
  }
}

.c-logo-card-mobile {
  max-width: 212px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  padding: 16px;

  img {
    width: 138px;
    height: 60px;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      margin: 0;
      color: rgba(27, 27, 27, 0.8);
      font-family: $source-font;
      font-size: 13px;
      font-weight: 400;
      line-height: 130%; /* 16.9px */
      opacity: 0.8;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $c-red;
      font-family: $source-font;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%; /* 16px */
    }
  }
}
