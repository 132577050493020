.c-dropdown-menu-mobile {
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    width: 100%;
    height: 8px;
  }

  .content {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding: 18px 0px;
    color: $dark-gray;
    font-family: $source-font;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%; /* 16px */
  }

  .list {
    padding: 0;
    margin: 0;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    list-style-type: none;
    max-height: 0px; /* Initially collapsed */
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    opacity: 0;

    &.active {
      opacity: 1;
      max-height: 1000px; /* Adjust based on content size */
      overflow: visible;
    }

    .list-item {
      a {
        padding: 0;
        color: $body-2;
        font-family: $source-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
      }
    }
  }
}

.c-mobile-sub-sub {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__menu {
    color: $body-2;
    font-family: $source-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    display: flex;
    justify-content: space-between;
    gap: 2px;
    width: 100%;

    svg {
      display: flex;
      flex-shrink: 0;
    }

    &.is-open {
      color: #000;
      font-weight: 600;
    }
  }

  &__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 0px; /* Initially collapsed */
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    opacity: 0;

    &.active {
      opacity: 1;
      max-height: 1000px; /* Adjust based on content size */
      overflow: visible;
    }
  }
}
